import instance from './axios'
import axios from 'axios'

// 获取系统参数
export const sysConfig = (para)=>{
  return instance.get('/case/sysConfig',{params:para})
}
// 修改系统参数
export const updateSysConfig = (para)=>{
  return instance.post('/case/updateSysConfig',para)
}
// 招标类型编辑
export const updateCaseType = (para)=>{
  return instance.post('/case/updateCaseType',para)
}
// 招标类型新增
export const addCaseType = (para)=>{
  return instance.post('/case/addCaseType',para)
}

// 招标类型列表
export const caseTypeList = (para)=>{
  return instance.get('/case/caseTypeList',{params:para})
}
// 招标类型详情
export const caseTypeDetail = (para)=>{
  return instance.get('/case/caseTypeDetail',{params:para})
}
// 后台账号管理列表
export const staffList = (para)=>{
  return instance.get('/staff/list',{params:para})
}
// 后台账号新增
export const staffAdd = (para)=>{
  return instance.post('/staff/add',para)
}
// 后台账号详情
export const staffDetail = (para)=>{
  return instance.get('/staff/detail',{params:para})
}
// 后台账号重置密码
export const resetPassword = (para)=>{
  return instance.post('/staff/reset/password',para)
}
// 后台账号修改
export const staffUpdate = (para)=>{
  return instance.post('/staff/update',para)
}
// 获取公告列表
export const getAnnouncementList = (para)=>{
  return instance.get('/operate/getAnnouncementList',{params:para})
}
// 获取banner列表
export const getBannerList = (para)=>{
  return instance.get('/operate/getBannerList',{params:para})
}
// 删除公告
export const deleteAnnouncement = (para)=>{
  return instance.post('/operate/deleteAnnouncement',para)
}
// 删除banner
export const deleteBanner = (para)=>{
  return instance.post('/operate/deleteBanner',para)
}
// 保存或修改公告
export const saveOrUpdateAnnouncement = (para)=>{
  return instance.post('/operate/saveOrUpdateAnnouncement',para)
}
// 新增或修改banner
export const saveOrUpdateBanner = (para)=>{
  return instance.post('/operate/saveOrUpdateBanner',para)
}
// 新增或者修改document
export const saveOrUpdateDocument = (para)=>{
  return instance.post('/operate/saveOrUpdateDocument',para)
}
// 获取document列表
export const getDocumentList = (para)=>{
  return instance.get('/operate/getDocumentList',{params:para})
}

// 删除document
export const deleteDocument = (para)=>{
  return instance.post('/operate/deleteDocument',para)
}
// 获取document详情
export const getDocumentDetail = (para)=>{
  return axios.get('/rest-admin/open/operate/getDocumentDetail',{params:para})
}
// 获取操作日志
export const operateLogAdmin = (para)=>{
  return instance.get('/operateLogAdmin/list',{params:para})
}