<template>
  <el-dialog :title="operType==='add'?'添加公告':'编辑公告'" :visible.sync="dialogVisible" width="600px">
  <el-form :model="form" :rules="rules" ref="addNoticeForm" label-width="100px">
    <el-form-item label="公告标题" prop="name">
      <el-input v-model="form.name" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="开始时间" prop="beginTime">
      <el-date-picker 
      value-format="yyyy-MM-dd HH:mm:ss" 
      type="datetime" 
      placeholder="选择日期" 
      v-model="form.beginTime" 
      style="width: 203px;"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="到期时间" prop="endTime">
      <el-date-picker 
      value-format="yyyy-MM-dd HH:mm:ss" 
      :picker-options="pickerOptions" 
      type="datetime" placeholder="选择日期" 
      v-model="form.endTime" 
      style="width: 203px;"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="固定跳转类型">
      <el-select v-model="form.fixedJumpType" placeholder="请选择">
        <el-option :label="item" :value="key" v-for="(item,key) in fixedJumpTypeOptions" :key="key"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="标的ID" v-if="form.fixedJumpType == '2'">
        <el-input v-model="form.jumpUrl" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="跳转url" v-if="form.fixedJumpType == '1'">
      <el-input v-model="form.jumpUrl" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="排序号">
      <el-input-number size="small" v-model="form.sort" :min="0"></el-input-number>
    </el-form-item>
    <el-form-item label="名称描述">
      <el-input v-model="form.nameDesc" style="width:400px;" type="textarea"></el-input>
    </el-form-item>
    <el-form-item label="公告类型">
      <el-select v-model="noticeType" placeholder="请选择">
        <el-option label="文字" value=""></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="ensure">确 认</el-button>
    <el-button @click="cancel">关 闭</el-button>
  </div>
  </el-dialog>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data () {
    return {
      dialogVisible:false,
      noticeType:'',
      form:{
        id:'',
        name:'',
        fixedJumpType:'0',
        jumpUrl:'',
        beginTime:'',
        endTime:'',
        sort:0,
        nameDesc:''
      },
      fixedJumpTypeOptions:{//跳转类型
        '0':'无跳转',
        '1':'超链接和h5',
        '3':'招标大厅'
      },
      rules:{
        name:[
          {required:true,message:'请输入标题',trigger:'blur'},
          {min:1,max:30,message:'公告标题不超过30个字',trigger:'blur'}
        ],
        beginTime:[ 
          {type:'string',required:true,message:'请选择日期',trigger:'change'}
        ],
        endTime:[
          {type:'string',required:true,message:'请选择日期',trigger:'change'}
        ]

      },
      pickerOptions:{
        disabledDate(time){
          return time.getTime() < Date.now();
        },
      }
    };
  },
  props:['operType'],
  components: {},
  computed: {
  },
  methods: {
    
    ensure(){
      this.$refs.addNoticeForm.validate((valid)=>{
        if(valid){
          if(this.form.fixedJumpType !=='1' && this.form.fixedJumpType !=='2'){
            this.form.jumpUrl = '';
          }
          this.$emit('ensure',this.form);
          this.dialogVisible = false;
        }else{
          console.log('error submit!!');
          return false;
        }
      })
      
    },
    cancel(){
      this.$refs.addNoticeForm.resetFields();
      this.dialogVisible = false;
    }
  }
}

</script>
<style lang='scss' scoped>
</style>