// 后台-公告管理
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
      <template v-slot:btns>
        <div class="btns">
          <el-button type="primary" size="medium" @click="addHandle">添加公告</el-button>
        </div>
      </template>
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="公告ID">
        </el-table-column>
        <el-table-column
          prop="name"
          label="公告标题">
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="到期时间">
        </el-table-column>
        <el-table-column
          prop="beginTime"
          label="生效时间">
        </el-table-column>
        <el-table-column
          prop="fixedJumpType"
          label="公告类型">
          <template slot-scope="scope">
            {{fixedJumpTypeOptions[scope.row.fixedJumpType]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="jumpUrl"
          label="跳转url">
          <template slot-scope="scope">
            {{scope.row.fixedJumpType =='2'?'标的ID:'+scope.row.jumpUrl:scope.row.jumpUrl}}
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
        </el-table-column>
        <el-table-column
          prop="dept"
          label="最后操作用户">
          <template slot-scope="scope">
            {{scope.row.operateId}}：{{scope.row.operateAccountName}}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="最后操作时间">
        </el-table-column>
        <el-table-column
          prop="onlineFlag"
          :filters="statusFilter"
          column-key="onlineFlag"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.green}" v-show="scope.row.onlineFlag ==1">有效</span>
            <span :style="{'color':COLOR.red}" v-show="scope.row.onlineFlag ==0">无效</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="val"
          label="操作">
          <template slot-scope="scope">
              <el-button type="text" @click="checkHandle(scope.row)">查看</el-button>
              <el-divider direction="vertical"></el-divider>
              
              <el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              
              <el-button type="text" @click="userHandle(scope.row)">{{scope.row.onlineFlag===1?'无效':'有效'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <add-notice ref="addNotice" @ensure="addEnsure" :operType="operType"></add-notice>
    <notice-detail :info="info" ref="noticeDetail"></notice-detail>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import AddNotice from './coms/add-notice.vue'
import NoticeDetail from './coms/notice-detail.vue'
import {getAnnouncementList,saveOrUpdateAnnouncement} from '../../api/system'
import {mapState} from 'vuex'
export default {
  name:'adminNotice',
  data () {
    return {
      total:0,
      form:{
        perPage:10,
        page:1,
        onlineFlag:""
      },
      info:{},
      conditions:[
        {value:'name',label:'标题'}
      ],
      tableData:[
      ],
      operType:'add',
      statusFilter:[
          {text: '有效', value: 1},
          {text: '无效', value: 0}
      ]

    };
  },
  components: {
    TableSearch,
    AddNotice,
    NoticeDetail
  },
  computed: {
    ...mapState({
      fixedJumpTypeOptions: state=>state.dictionary.fixedJumpTypeOptions
    })
  },
  methods: {
    getList(){
      getAnnouncementList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        onlineFlag:this.form.onlineFlag
        },para);
      this.getList();
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addEnsure(form){
      let para = Object.assign({},form);
      if(this.operType === 'add'){
        delete para.id;
      }
      saveOrUpdateAnnouncement(para).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.getList();
        }else{
          this.$message.warning(data.desc);
        }
      })
      
    },
    addHandle(){
      this.operType = 'add';
      this.$refs.addNotice.dialogVisible = true;
      this.$refs.addNotice.form = {
        id:'',
        name:'',
        fixedJumpType:'0',
        jumpUrl:'',
        beginTime:'',
        endTime:'',
        sort:0,
        nameDesc:''
      };
      this.$nextTick(function(){
        this.$refs.addNotice.$refs.addNoticeForm.clearValidate();
      })
      
    },
    userHandle(row){
      let str = row.onlineFlag === 1?'无效':'有效';
      let flag = row.onlineFlag === 1? 0:1;
      this.$confirm('此操作将修改公告状态为'+ str +', 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let para = Object.assign({},row);
          para.onlineFlag = flag;
          saveOrUpdateAnnouncement(para).then(res=>{
            let data = res.data;
            if(data.code === '000'){
              this.$message.success(data.desc);
              this.getList();
            }else{
              this.$message.warning(data.desc);
            }
          })
        })
    },
    checkHandle(row){
      this.info = row;
      this.$refs.noticeDetail.dialogVisible = true;
    },
    editHandle(row){
      this.operType = 'edit';
      let form = this.$refs.addNotice.form;
      for(let key in form){
        form[key] = row[key];
      }
      this.$refs.addNotice.dialogVisible = true;
    },
    filterChange(filters){
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();
    },
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>